import { mapActions, mapGetters } from 'vuex';
import { AB_TEST_ID } from '../../../../../constants/abTest';
import { PAY_BUTTONS } from '../../../Payment/PaymentWrapper';

export default {
  name: 'UpdateStatisticsPopup',
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
    PaymentWrapper: () => import('../../../Payment/PaymentWrapper/index.vue'),
    'static-content': () => import('./components/StaticContent/index.vue'),
    'dynamic-content': () => import('./components/DynamicContent/index.vue'),
  },
  data() {
    return {
      componentsMap: {
        firstVariant: 'static-content',
        secondVariant: 'dynamic-content',
      },
      backendData: null,
      loading: true,
      slonikParams: { event_id: 1554 },
      fetchParams: {
        selected: {
          updatePublicationDate: true,
          updateAdvStatistic: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getABtestVariant: 'others/analytic/getABtestVariant',
      popup: 'cabinet/advertisements/popups/popup',
    }),
    info() {
      return this.popup?.info || {};
    },
    abTestVariant() {
      return this.getABtestVariant(AB_TEST_ID.RESET_STATISTIC);
    },
    isStaticComponent() {
      return this.abTestVariant === 2;
    },
    selectedComponent() {
      return this.isStaticComponent ? this.componentsMap.firstVariant : this.componentsMap.secondVariant;
    },
    componentData() {
      if (!this.backendData) return null;
      return this.backendData[this.isStaticComponent ? 'firstVariant' : 'secondVariant'];
    },
    payButtons() {
      return this.componentData?.orderData?.payButtons;
    },
    modalWidth() {
      return this.isStaticComponent ? 390 : 790;
    },
    modalClass() {
      return this.loading ? 'modal-bottom item-level-loaded' : 'modal-bottom';
    },
  },
  methods: {
    ...mapActions({
      closePopup: 'cabinet/advertisements/popups/close',
      openPopup: 'cabinet/advertisements/popups/open',
      updateStepPublication: 'publication/updateStepPublication',
      fetchABtestVariants: 'others/analytic/fetchABtestVariants',
    }),
    fetch() {
      this.loading = true;

      this.updateStepPublication({
        advertisementId: this.info.id,
        queryType: 'popup/updateStatisticAndPublicationDate',
        params: this.fetchParams,
      }).then((response) => {
        this.loading = false;
        this.backendData = response;
      });
      this.loading = true;
    },
    updateNewValue({ id, value }) {
      this.fetchParams.selected[id] = value;
      this.logAnalytic({
        action_block: 1,
        сlick_action: id === 'updatePublicationDate' ? 1 : 3,
      });
      this.fetch();
    },
    onPaymentSuccess({ orderId, name }) {
      const [updatePublicationDate, updateAdvStatistic] = Object.keys(this.fetchParams.selected);
      this.logPaymentButton(name, {
        request_type: 4,
        billing_order_id: orderId,
        current_url: window.location.href,
        sending_id: [
          [updatePublicationDate],
          [updateAdvStatistic],
        ],
      });

      this.onClose();
      this.openPopup({ name: 'UpdatedStatisticsPopup' });
    },
    onPaymentButtonClick({ name }) {
      this.logPaymentButton(name, { request_type: 3 });
    },
    logPaymentButton(name, logParams) {
      const paymentButtonsIdsMap = {
        [PAY_BUTTONS.APPLE]: 117,
        [PAY_BUTTONS.GOOGLE]: 126,
        [PAY_BUTTONS.PRYVAT]: 115,
        [PAY_BUTTONS.DEFAULT]: 5000,
      };

      this.logAnalytic({
        ...logParams,
        payment_type: paymentButtonsIdsMap[name],
        payment_summ: this.componentData?.orderData?.price?.value,
        sending_id: [
          [Number(this.fetchParams.selected.updatePublicationDate)],
          [Number(this.fetchParams.selected.updateAdvStatistic)],
        ],
        balance_personal: [
          [this.componentData?.featuresData?.updatePublicationDate?.price],
          [this.componentData?.featuresData?.updateAdvStatistic?.price],
        ],
      });
    },
    logAnalytic(params) {
      this._slonik({
        ...this.slonikParams,
        ...params,
      });
    },
    onClose() {
      this.logAnalytic({ request_type: 2 });
      this.closePopup();
    },
  },
  async mounted() {
    await this.fetchABtestVariants({
      testName: AB_TEST_ID.RESET_STATISTIC,
      params: `?auto_id=${this.info.id}`,
    });
    this.fetch();
    this.logAnalytic({ request_type: 1 });
  },
};
